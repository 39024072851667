import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import VideoMP4 from 'src/assets/videos/chapter_4/4-1-1-bg.mp4';
import Video from 'src/assets/videos/chapter_4/4-1-1-bg.webm';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { SlideLarge, FlexWrapper } from '../../_styles';

const Slide = ({ query }) => (
  <SlideLarge>
    <ParallaxMordo>
      <Mordo
        mainscene
        move={{ x: -20, y: -10 }}
        background={{ color: 'transparent' }}
      >
        <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />

      </Mordo>
      <Mordo
        flexi
        scene={2}
        move={{ x: 10, y: 20 }}
      >
        <FlexWrapper>
          <LeftColContent body={query.leftColumn.body} />
        </FlexWrapper>
      </Mordo>
    </ParallaxMordo>
  </SlideLarge>
);

export default Slide;
