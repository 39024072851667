import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import webm from 'src/assets/videos/chapter_4/4-1-1-mobile.webm';
import mp4 from 'src/assets/videos/chapter_4/4-1-1-mobile.mp4';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="900px">
    <BackgroundAnimation src={webm} mp4={mp4}>
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ marginTop: '1rem' }} body={query.mdx.body} />
    </BackgroundAnimation>
  </BlockContainer>
);

export default Slide;
