import styled, { keyframes, css } from 'styled-components';

const wrapperAnimationEnter = keyframes`
    0%{
        transform: scaleX(0);
        opacity: 0;
    }
    100%{
        transform: scaleX(1);
        opacity: 1;
    }
`;

const wrapperAnimationExit = keyframes`
    0%{
        transform: scaleX(1);
        opacity: 1;
    }
    100%{
        transform: scaleX(0);
        opacity: 0;
    }
`;

const dotAnimationEnter = keyframes`
    0%{
        transform: translate(-50%, -50%) scale(0.5);
    }
    100%{
        transform: translate(-50%, -50%) scale(1);
    }
`;

const dotAnimationExit = keyframes`
    0%{
        transform: translate(-50%, -50%) scale(1);
    }
    100%{
        transform: translate(-50%, -50%) scale(0.5);
    }
`;

const dotAnimation = ({ visible }) => {
  if (visible) {
    return css`
      animation: ${dotAnimationEnter} 0.8s cubic-bezier(0.78, 0, 0.235, 1)
        1100ms forwards;
    `;
  }
  return css`
    animation: ${dotAnimationExit} 0.8s cubic-bezier(0.78, 0, 0.235, 1) forwards;
  `;
};

const wrapperAnimation = ({ visible }) => {
  if (visible) {
    return css`
      animation: ${wrapperAnimationEnter} 0.8s cubic-bezier(0.78, 0, 0.235, 1)
        200ms forwards;
    `;
  }
  return css`
    animation: ${wrapperAnimationExit} 0.8s cubic-bezier(0.78, 0, 0.235, 1)
      forwards;
  `;
};

export const Container = styled.div`
  position: relative;
  top: ${({ top }) => `${top}px`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdfdfd;
  color: #0d0719;
  font-family: "Red Hat Display";
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem;
  width: 80%;
  margin: 0 auto;
  border-radius: 7px;
  transform: scaleX(0);
  opacity: 0;
  ${wrapperAnimation};
`;

export const Text = styled.p`
  text-align: center;
  width: 95%;
`;

export const Dot = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ff0000;
  position: relative;
`;

export const DotHover = styled.div`
  width: 8px;
  height: 8px;
  background-color: #f3f2f0;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  ${dotAnimation};
`;
