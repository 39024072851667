import React from 'react';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  Container, Text, Dot, DotHover,
} from './styles';

const SignedPanel = ({
  name, top, body, image,
}) => {
  const dispatch = useDispatch();
  const dispatchModal = () => {
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: <MDXRenderer>{body}</MDXRenderer> });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: image });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'mobile' });
  };
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <Container ref={ref} visible={inView} top={top} onClick={() => dispatchModal()}>
      <Text>
        {name}
      </Text>
      <Dot>
        <DotHover visible={inView} />
      </Dot>
    </Container>
  );
};

export default SignedPanel;
