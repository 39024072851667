import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import SignedPanel from 'src/components/mobile/signedPanel/signedPanel';
import { BlockContainer } from '../../_styles';


const Slide = ({ query }) => (

  < BlockContainer height="2600px" >
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <TextAnimation containerStyle={{ height: '11%', marginTop: '1rem' }} body={query.mdx.body} />
      <SignedPanel name={query.hoverDot.exports.hoverInfo[0].insideText} top="300" body={query.iwanSkworcowStiepanow.body} />
      <SignedPanel name={query.hoverDot.exports.hoverInfo[1].insideText} top="800" body={query.feliksDzierzynski.body} />
      <SignedPanel name={query.hoverDot.exports.hoverInfo[2].insideText} top="1300" body={query.julianMarchlewski.body} />
      <SignedPanel name={query.hoverDot.exports.hoverInfo[3].insideText} top="1800" body={query.feliksKon.body} />
    </BackgroundPhoto>
  </BlockContainer >
);

export default Slide;
