import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import Caption from 'src/components/desktop/caption/caption';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_1/2-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer1: file(relativePath: { eq: "chapter_4/sub_1/binoculars.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  feliksDzierzynski: file(relativePath: { eq: "chapter_4/sub_1/Feliks-Dzierzynski.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  julianMarchlewski: file(relativePath: { eq: "chapter_4/sub_1/Julian-Marchlewski.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  feliksKon: file(relativePath: { eq: "chapter_4/sub_1/Feliks-Kon.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  iwanSkworcowStiepanow: file(relativePath: { eq: "chapter_4/sub_1/Iwan-Skorcow-Stiepanow.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
},
modal1: file(relativePath: { eq: "chapter_1/sub_2/1-modal1.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 762)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
}
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'horizontal' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };
  return (
    <SlideLarge>
      <ParallaxMordo column>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
          move={{ x: -20, y: -10 }}
        />

        <Mordo
          scenelayer
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
          opacity={1}
        />

        <Mordo
          scenelayer
          scene={3}
        >
          {query.hoverDot.exports.hoverInfo && query.hoverDot.exports.hoverInfo.map((dot, index) => <HoverDot key={`HoverDot_${index}`} insideText={dot.insideText} positionLeft={dot.positionLeft} positionTop={dot.positionTop} clickDot={() => clickHandler(dot.identifier)} />)}
        </Mordo>
        <Mordo
          flexi
          scene={2}
          move={{ x: -5, y: -5 }}
        >
          <div css={{
            alignSelf: 'center', width: '100%', margin: '25% auto 0',
          }}
          >
            <Caption body={query.caption.body} specialSize="1.25" />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
